// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-calendar_EbXiR{display:flex;flex-direction:column;gap:48px}@media(max-width:1085px){.advent-calendar_EbXiR{align-items:center}}.advent-calendar__grid_gRH5J{display:grid;grid-template-columns:repeat(7,minmax(100px,1fr));gap:12px;width:100%}@media(max-width:1085px){.advent-calendar__grid_gRH5J{grid-template-columns:repeat(12,1fr)}.advent-calendar__grid-item_yyWwl{grid-column:span 2}.advent-calendar__grid-item_yyWwl:last-child:nth-child(6n+1){grid-column-end:8}.advent-calendar__grid-item_yyWwl:last-child:nth-child(6n-4){grid-column-end:9}.advent-calendar__grid-item_yyWwl:nth-last-child(2):nth-child(6n+1){grid-column-end:7}.advent-calendar__grid-item_yyWwl:nth-last-child(3):nth-child(6n+1){grid-column-end:6}.advent-calendar__grid-item_yyWwl:nth-last-child(4):nth-child(6n+1){grid-column-end:5}.advent-calendar__grid-item_yyWwl:nth-last-child(5):nth-child(6n+1){grid-column-end:4}}.advent-calendar__button_cvbRX{max-width:343px}.advent-calendar__slider-slide_um4YK{width:100%;margin:0 6px}.advent-calendar__slider-slide_um4YK:first-child{margin-left:0}.advent-calendar__slider-slide_um4YK:last-child{margin-right:0}.advent-calendar__slider-group_Annw7{display:grid;grid-template-columns:repeat(2,minmax(140px,1fr));gap:12px}.advent-calendar__slider-card_Lq8oB{max-width:100%}.advent-calendar__pagination-footer_dnrtk{display:flex;justify-content:center;padding:16px 0;margin-top:6px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-calendar": `advent-calendar_EbXiR`,
	"advent-calendar__grid": `advent-calendar__grid_gRH5J`,
	"advent-calendar__grid-item": `advent-calendar__grid-item_yyWwl`,
	"advent-calendar__button": `advent-calendar__button_cvbRX`,
	"advent-calendar__slider-slide": `advent-calendar__slider-slide_um4YK`,
	"advent-calendar__slider-group": `advent-calendar__slider-group_Annw7`,
	"advent-calendar__slider-card": `advent-calendar__slider-card_Lq8oB`,
	"advent-calendar__pagination-footer": `advent-calendar__pagination-footer_dnrtk`
};
export default ___CSS_LOADER_EXPORT___;
