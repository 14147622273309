// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-calendar-tournaments-slider-navigation_DC9P1{display:flex;gap:12px;justify-content:space-between;pointer-events:none}.advent-calendar-tournaments-slider-navigation_DC9P1 .advent-calendar-tournaments-slider-navigation__button_WaFrw{color:var(--DTextDefault);pointer-events:all;background:var(--DLayer2)}@media(hover:hover)and (pointer:fine){.advent-calendar-tournaments-slider-navigation_DC9P1 .advent-calendar-tournaments-slider-navigation__button_WaFrw:hover:not(:active):not(:focus-visible):not([disabled]){color:var(--DTextDefault);background:var(--DHighlight)}}.advent-calendar-tournaments-slider-navigation_DC9P1 .advent-calendar-tournaments-slider-navigation__button_WaFrw:active:not([disabled]),.advent-calendar-tournaments-slider-navigation_DC9P1 .advent-calendar-tournaments-slider-navigation__button_WaFrw:focus-visible,.advent-calendar-tournaments-slider-navigation_DC9P1 .advent-calendar-tournaments-slider-navigation__button_WaFrw:hover:active:not([disabled]){color:var(--DTextDefault);background:var(--DLayer1);border:none;box-shadow:inset 0 .66px 0 1px #394046;transform:scale(1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-calendar-tournaments-slider-navigation": `advent-calendar-tournaments-slider-navigation_DC9P1`,
	"advent-calendar-tournaments-slider-navigation__button": `advent-calendar-tournaments-slider-navigation__button_WaFrw`
};
export default ___CSS_LOADER_EXPORT___;
