import { onUnmounted, computed, onMounted, toRef } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAdventStore } from 'web/src/modules/landings/submodules/advent/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function useAdventPrizesModalRoutePage() {
    const adventStore = useAdventStore();
    const analytics = useAnalytics();
    const customerDataStore = useCustomerDataStore();
    const { isLoggedIn } = useIsLoggedIn();
    const rewards = toRef(()=>adventStore.rewards);
    const customerId = toRef(()=>customerDataStore.customerData?.customerLogin);
    const customerCountry = toRef(()=>customerDataStore.customerData?.country);
    const customerIsVip = toRef(()=>customerDataStore.customerData?.isVip);
    const { fetchPack, fetchAllPacks, collectPackReward } = adventStore;
    const packRewards = computed(()=>rewards.value);
    async function onRewardButtonClick(reward) {
        await collectPackReward(reward.rewardId);
        const event = reward.collected ? AnalyticsEvent.ADVENT_APPLIED_REWARD : AnalyticsEvent.ADVENT_OBTAIN_REWARD;
        analytics.pushGTM(event, {
            event,
            customer_id: customerId.value,
            customer_status: 'logged_in',
            vs_lw: customerIsVip.value,
            full_url: window.location.href,
            country: customerCountry.value,
            reward_id: reward.rewardId,
            reward_type: reward.type,
            reward_description: reward.visualDetails.title
        });
    }
    onMounted(async ()=>{
        if (isLoggedIn.value) await fetchPack();
    });
    onUnmounted(async ()=>{
        await fetchAllPacks();
    });
    return {
        packRewards,
        onRewardButtonClick
    };
}
