// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .advent-calendar-tournaments_P8lOb{position:relative}html.app__layout--desktop .advent-calendar-tournaments__swiper-slide__aYI7{flex:1;width:auto;margin-right:24px}html.app__layout--desktop .advent-calendar-tournaments__swiper-slide__aYI7:last-child{margin-right:0}html.app__layout--desktop .advent-calendar-tournaments__swiper-footer_aNTzg{display:flex;justify-content:center;padding:16px}html.app__layout--desktop .advent-calendar-tournaments__swiper-navigation-top_ytIZG{position:absolute;top:50%;z-index:10;width:100%;margin-top:88px;pointer-events:none;transform:translateY(-50%)}@media(max-width:1280px){html.app__layout--desktop .advent-calendar-tournaments__event-container_ZZFG5{width:calc(100% - 144px);margin:0 auto;overflow:hidden}}html.app__layout--desktop .advent-calendar-tournaments__header_meSh5{margin-bottom:48px}@media(max-width:1280px){html.app__layout--desktop .advent-calendar-tournaments__header_meSh5{text-align:center}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-calendar-tournaments": `advent-calendar-tournaments_P8lOb`,
	"advent-calendar-tournaments__swiper-slide": `advent-calendar-tournaments__swiper-slide__aYI7`,
	"advent-calendar-tournaments__swiper-footer": `advent-calendar-tournaments__swiper-footer_aNTzg`,
	"advent-calendar-tournaments__swiper-navigation-top": `advent-calendar-tournaments__swiper-navigation-top_ytIZG`,
	"advent-calendar-tournaments__event-container": `advent-calendar-tournaments__event-container_ZZFG5`,
	"advent-calendar-tournaments__header": `advent-calendar-tournaments__header_meSh5`
};
export default ___CSS_LOADER_EXPORT___;
