// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-header_K6mMO{display:flex;align-items:center;justify-content:center;width:100%;height:54px}@media(min-width:1024px){.landing-header_K6mMO{height:88px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-header": `landing-header_K6mMO`
};
export default ___CSS_LOADER_EXPORT___;
