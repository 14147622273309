import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import LandingBrand from 'web/src/modules/landings/system/components/LandingBrand/LandingBrand.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingHeader',
    props: {
        isDarkLogo: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: "/",
                class: _normalizeClass(_ctx.$style['landing-header'])
            }, [
                _createVNode(LandingBrand, {
                    "is-dark": _ctx.isDarkLogo
                }, null, 8, [
                    "is-dark"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingHeader'
                ]
            ]);
        };
    }
});
