export function usePlaceImages() {
    const firstPlaceSrc = require('web/src/assets/images/landing/euro/tournaments/1_place_bets.svg');
    const secondPlaceSrc = require('web/src/assets/images/landing/euro/tournaments/2_place_bets.svg');
    const thirdPlaceSrc = require('web/src/assets/images/landing/euro/tournaments/3_place_bets.svg');
    const placeImages = [
        firstPlaceSrc,
        secondPlaceSrc,
        thirdPlaceSrc
    ];
    return {
        placeImages
    };
}
