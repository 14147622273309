import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import { Locale } from '@leon-hub/locale';
import RouteName from '@leon-hub/routing-config-names';
import LandingHeader from 'web/src/modules/landings/system/components/LandingHeader/LandingHeader.vue';
import LandingHeroFullScreen from 'web/src/modules/landings/system/components/LandingHeroFullScreen/LandingHeroFullScreen.vue';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import useGlobalLandingClass from 'web/src/modules/landings/useGlobalLandingClass';
import { LandingStyleAnimation, LandingStylePreset, OrientationImage } from 'web/src/modules/landings/system/types';
import { useAppDownloadStore } from 'web/src/modules/app-download/store';
import { useLandingImages } from 'web/src/modules/landings/submodules/android/useLandingImages';
import LandingList from 'web/src/modules/landings/system/components/LandingList/LandingList.vue';
import { textAlign } from 'web/src/modules/landings/system/components/LandingList/types';
import { LandingItemDirection, LandingItemImageSize, LandingItemStep } from 'web/src/modules/landings/system/components/LandingListItem/types';
import LandingBanner from 'web/src/modules/landings/system/components/LandingBanner/LandingBanner.vue';
import { LandingChevronDecor } from 'web/src/modules/landings/system/components/LandingChevron/types';
import { SocialIconList } from 'web/src/modules/landings/system/components/LandingSocialLink/types';
import LandingFooter from 'web/src/modules/landings/system/components/LandingFooter/LandingFooter.vue';
import { useFooterCopyright } from 'web/src/modules/core/components/FooterCopyright/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AndroidRoutePage',
    setup (__props) {
        const siteConfigStore = useSiteConfigStore();
        const isLandingAndroidEnabled = toRef(()=>siteConfigStore.isLandingAndroidEnabled);
        const mobileAppCordovaBlock = toRef(()=>siteConfigStore.mobileAppCordovaBlock);
        const mobileAppDownloadEnabled = computed(()=>!!mobileAppCordovaBlock.value?.mobileAppDownloadEnabled);
        const isAndroidAppEnabled = computed(()=>mobileAppDownloadEnabled.value && !!mobileAppCordovaBlock.value?.mobileAppAndroidDownloadEnabled);
        const { $translate } = useI18n();
        const { locale } = useI18nLocale();
        const router = useRouter();
        const { presetClass, animationClass } = useGlobalLandingClass(LandingStylePreset.PRESET_1, LandingStyleAnimation.ANIMATION_1);
        const downloadAppStore = useAppDownloadStore();
        const appDownloadLink = toRef(()=>downloadAppStore.androidAppUrl);
        const { firstImage, srcFirstBgImage, thirdImage } = useLandingImages();
        const { secondListImages, fourthListImages } = useLandingImages();
        const redirectCheckCompleted = ref(false);
        const androidLanding = ref();
        onMounted(async ()=>{
            if (isLandingAndroidEnabled.value) redirectCheckCompleted.value = true;
            else if (mobileAppDownloadEnabled.value && isAndroidAppEnabled.value) {
                await router.push({
                    name: RouteName.HOME
                });
                window.location.href = `${mobileAppCordovaBlock.value?.mobileAppAndroidDownloadUrl ?? '/app'}?l=${locale.value}`;
            } else router.push({
                name: RouteName.ERROR_NOT_FOUND_404
            });
        });
        require('web/src/modules/landings/system/images/galaxy.png');
        require('web/src/modules/landings/system/images/app-gallery.svg');
        require('web/src/modules/landings/system/images/rustore.svg');
        const appList = void 0;
        const buttonIcon = IconName.DOWNLOAD_2;
        const HeroScreenData = computed(()=>({
                title: $translate('WEB2_ANDROID_LANDING_TITLE_1').value,
                chevron: $translate('WEB2_ANDROID_LANDING_CHEVRON_1').value,
                label: $translate('WEB2_ANDROID_LANDING_LABEL_1').value,
                buttonLabel: $translate('WEB2_ANDROID_LANDING_DOWNLOAD_APP').value,
                buttonIcon,
                imageSrc: firstImage.value,
                bgSrcMobile: srcFirstBgImage.value,
                bgSrcDesk: srcFirstBgImage.value,
                imageKind: locale.value === Locale.EN_IN ? OrientationImage.HORIZONTAL : OrientationImage.VERTICAL,
                appList,
                chevronDecor: LandingChevronDecor.STAR
            }));
        const itemProps_1 = {
            index: 0,
            imageSize: LandingItemImageSize.SIZE_146,
            direction: LandingItemDirection.ROW_CENTER
        };
        const list_1 = computed(()=>[
                {
                    ...itemProps_1,
                    imageSrc: secondListImages.value[0],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_2_1').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_2_1').value
                },
                {
                    ...itemProps_1,
                    index: 1,
                    imageSrc: secondListImages.value[1],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_2_2').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_2_2').value
                },
                {
                    ...itemProps_1,
                    index: 2,
                    imageSrc: secondListImages.value[2],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_2_3').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_2_3').value
                }
            ]);
        const listSectionData_1 = computed(()=>({
                list: list_1.value,
                title: $translate('WEB2_ANDROID_LANDING_TITLE_2').value,
                isVerticalPadding: true
            }));
        const banner = computed(()=>({
                chevron: $translate('WEB2_ANDROID_LANDING_CHEVRON_3').value,
                title: $translate('WEB2_ANDROID_LANDING_TITLE_3').value,
                label: $translate('WEB2_ANDROID_LANDING_LABEL_3').value,
                imageSrc: thirdImage.value,
                appList,
                chevronDecor: LandingChevronDecor.CIRCLE,
                buttonLabel: $translate('WEB2_ANDROID_LANDING_DOWNLOAD_APP').value,
                buttonIcon
            }));
        const itemProps_2 = {
            index: 0,
            imageSize: LandingItemImageSize.SIZE_146,
            direction: LandingItemDirection.COLUMN_LEFT,
            step: LandingItemStep.NUMBER
        };
        const list_2 = computed(()=>[
                {
                    ...itemProps_2,
                    index: 0,
                    imageSrc: fourthListImages.value[0],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_4_1').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_4_1').value
                },
                {
                    ...itemProps_2,
                    index: 1,
                    imageSrc: fourthListImages.value[1],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_4_2').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_4_2').value
                },
                {
                    ...itemProps_2,
                    index: 2,
                    imageSrc: fourthListImages.value[2],
                    title: $translate('WEB2_ANDROID_LANDING_ITEM_TITLE_4_3').value,
                    label: $translate('WEB2_ANDROID_LANDING_ITEM_TEXT_4_3').value
                }
            ]);
        const listSectionData_2 = computed(()=>({
                list: list_2.value,
                title: $translate('WEB2_ANDROID_LANDING_TITLE_4').value,
                headerTextAlign: textAlign.LEFT,
                isVerticalPadding: true
            }));
        SocialIconList.VK, SocialIconList.TELEGRAM;
        const { copyright1, copyright2 } = useFooterCopyright();
        const footerData = computed(()=>({
                title: $translate('WEB2_ANDROID_LANDING_TITLE_5').value,
                buttonLabel: $translate('WEB2_ANDROID_LANDING_DOWNLOAD_APP').value,
                appList,
                isDarkBrand: true,
                socialList: void 0,
                copyrightList: [
                    copyright1.value,
                    copyright2.value
                ],
                buttonIcon
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return redirectCheckCompleted.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "androidLanding",
                ref: androidLanding,
                class: _normalizeClass({
                    [_unref(presetClass)]: true,
                    [_unref(animationClass)]: true,
                    [_ctx.$style['android-route-page']]: true
                })
            }, [
                _createVNode(LandingHeader, {
                    "is-dark-logo": ""
                }),
                _createVNode(LandingHeroFullScreen, _mergeProps(HeroScreenData.value, {
                    "button-link": appDownloadLink.value
                }), null, 16, [
                    "button-link"
                ]),
                _createVNode(LandingList, _mergeProps(listSectionData_1.value, {
                    class: {
                        [_ctx.$style['android-route-page__section']]: true
                    }
                }), null, 16, [
                    "class"
                ]),
                _createVNode(LandingBanner, _mergeProps(banner.value, {
                    class: _ctx.$style['android-route-page__section'],
                    "button-link": appDownloadLink.value
                }), null, 16, [
                    "class",
                    "button-link"
                ]),
                _createVNode(LandingList, _mergeProps(listSectionData_2.value, {
                    class: _ctx.$style['android-route-page__section']
                }), null, 16, [
                    "class"
                ]),
                _createVNode(LandingFooter, _mergeProps({
                    class: _ctx.$style['android-route-page__section']
                }, footerData.value, {
                    "button-link": appDownloadLink.value
                }), null, 16, [
                    "class",
                    "button-link"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'AndroidRoutePage'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
