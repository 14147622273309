import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useLandingButtonsClickHandlers } from 'web/src/modules/landings/composables/useLandingButtonsClickHandlers';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useAdventRoutePage } from './composables';
import AdventHero from '../../components/AdventHero/AdventHero.vue';
import AdventCalendarStub from '../../components/AdventCalendarStub/AdventCalendarStub.vue';
import AdventCalendar from '../../components/AdventCalendar/AdventCalendar.vue';
import AdventCalendarTournaments from '../../components/AdventCalendarTournaments/AdventCalendarTournaments.vue';
const isImageLoaded = true;
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventRoutePage',
    setup (__props) {
        const { adventCalendarItems, festivalDate, imageBackground, isBeforeStart, isFestivalEnded, isFestivalUnavailable, isLoggedIn, isPackCalculating, isPacksLoaded, onCalendarClick, onEndTimer } = useAdventRoutePage();
        const { openMyBonuses, openLogin, openPromotion } = useLandingButtonsClickHandlers();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['advent-page']]: true,
                    [_ctx.$style['advent-page--is-loading']]: !isImageLoaded
                })
            }, [
                _unref(imageBackground) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['advent-page__bg-wrapper'])
                }, [
                    _createVNode(VImage, {
                        src: _unref(imageBackground).src,
                        x1: _unref(imageBackground).x1,
                        x2: _unref(imageBackground).x2,
                        x3: _unref(imageBackground).x3,
                        x1webp: _unref(imageBackground).x1webp,
                        x2webp: _unref(imageBackground).x2webp,
                        x3webp: _unref(imageBackground).x3webp,
                        alt: "",
                        class: _normalizeClass(_ctx.$style['advent-page__bg'])
                    }, null, 8, [
                        "src",
                        "x1",
                        "x2",
                        "x3",
                        "x1webp",
                        "x2webp",
                        "x3webp",
                        "class"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-page__wrapper'])
                }, [
                    _createVNode(AdventHero, {
                        class: _normalizeClass(_ctx.$style['advent-page__hero']),
                        timestamp: _unref(festivalDate),
                        "is-before-start": _unref(isBeforeStart),
                        "is-festival-ended": _unref(isFestivalEnded),
                        onEndTimer: _unref(onEndTimer)
                    }, null, 8, [
                        "class",
                        "timestamp",
                        "is-before-start",
                        "is-festival-ended",
                        "onEndTimer"
                    ]),
                    _unref(isBeforeStart) || !_unref(isLoggedIn) || _unref(isFestivalUnavailable) ? (_openBlock(), _createBlock(AdventCalendarStub, {
                        key: 0,
                        "is-started": !_unref(isBeforeStart),
                        "is-logged-in": _unref(isLoggedIn),
                        "is-festival-unavailable": _unref(isFestivalUnavailable),
                        class: _normalizeClass(_ctx.$style['advent-page__section']),
                        onClick: _unref(openLogin)
                    }, null, 8, [
                        "is-started",
                        "is-logged-in",
                        "is-festival-unavailable",
                        "class",
                        "onClick"
                    ])) : (_openBlock(), _createBlock(AdventCalendar, {
                        key: 1,
                        items: _unref(adventCalendarItems),
                        class: _normalizeClass(_ctx.$style['advent-page__section']),
                        "is-pack-calculating": _unref(isPackCalculating),
                        "is-packs-loaded": _unref(isPacksLoaded),
                        onClick: _unref(onCalendarClick),
                        onWatchBonuses: _unref(openMyBonuses)
                    }, null, 8, [
                        "items",
                        "class",
                        "is-pack-calculating",
                        "is-packs-loaded",
                        "onClick",
                        "onWatchBonuses"
                    ])),
                    _createVNode(AdventCalendarTournaments, {
                        class: _normalizeClass(_ctx.$style['advent-page__section']),
                        "is-festival-unavailable": _unref(isFestivalUnavailable),
                        onClickOnDetails: _unref(openPromotion)
                    }, null, 8, [
                        "class",
                        "is-festival-unavailable",
                        "onClickOnDetails"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventRoutePage'
                ]
            ]);
        };
    }
});
