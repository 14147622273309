import { onBeforeMount, ref, toRef, watch } from 'vue';
import { logger } from '@leon-hub/logging';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAdventStore } from 'web/src/modules/landings/submodules/advent/store';
import { useLandingButtonsClickHandlers } from 'web/src/modules/landings/composables/useLandingButtonsClickHandlers';
export default function useAdventCalendarTournamentItem(props) {
    const { isLoggedIn } = useIsLoggedIn();
    const adventStore = useAdventStore();
    const promotionUpdatedData = toRef(()=>adventStore.promotionUpdatedData);
    const { openLogin } = useLandingButtonsClickHandlers();
    const leaderBoardData = ref(null);
    const isLoadingButton = ref(false);
    const isDataLoaded = ref(false);
    async function loadLeaderBoardData() {
        leaderBoardData.value = await adventStore.adventCalendarLeaderBoardRequest({
            actionUrl: props.promotion?.actionUrl || '',
            id: ''
        });
        isDataLoaded.value = true;
    }
    async function onClickPromoJoin() {
        if (!isLoggedIn.value) {
            openLogin();
            return;
        }
        await adventStore.updatePromotionDetails(props.promotion.actionUrl || '');
        const { actionButton, actionUrl: url } = promotionUpdatedData.value;
        if (actionButton && url) {
            isLoadingButton.value = true;
            await adventStore.adventCalendarDoButtonMutationPromotion({
                actionUrl: url,
                buttonType: actionButton.buttonType
            });
            await adventStore.loadPromotions();
            await loadLeaderBoardData();
            isLoadingButton.value = false;
        } else logger.error('No actionButton');
    }
    onBeforeMount(async ()=>{
        await loadLeaderBoardData();
    });
    watch(isLoggedIn, async ()=>{
        await loadLeaderBoardData();
    });
    return {
        isDataLoaded,
        isLoggedIn,
        isLoadingButton,
        leaderBoardData,
        onClickPromoJoin
    };
}
