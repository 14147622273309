// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .advent-page_pRllY{position:relative;min-width:976px;margin:-8px -16px 0;font-family:Mulish,sans-serif;background-color:var(--DLayer0)}html.app__layout--desktop .advent-page__bg-wrapper_DP7BA{position:absolute;top:0;left:50%;display:flex;justify-content:center;width:100%;overflow-x:hidden;transform:translateX(-50%)}html.app__layout--desktop .advent-page__bg_lEyaT{width:1440px;height:360px}html.app__layout--desktop .advent-page__wrapper_AWM_a{position:relative;z-index:10;display:block;width:100%;min-width:976px;max-width:1362px;padding:0 24px 180px;margin:0 auto}html.app__layout--desktop .advent-page__section_Ef0l_:not(:last-child){margin-bottom:180px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-page": `advent-page_pRllY`,
	"advent-page__bg-wrapper": `advent-page__bg-wrapper_DP7BA`,
	"advent-page__bg": `advent-page__bg_lEyaT`,
	"advent-page__wrapper": `advent-page__wrapper_AWM_a`,
	"advent-page__section": `advent-page__section_Ef0l_`
};
export default ___CSS_LOADER_EXPORT___;
