import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export const useLandingImages = ()=>{
    const siteConfigStore = useSiteConfigStore();
    const landingAndroidImageHeader = toRef(()=>siteConfigStore.landingAndroidImageHeader);
    const landingAndroidImageGamblingFirst = toRef(()=>siteConfigStore.landingAndroidImageGamblingFirst);
    const landingAndroidImageGamblingSecond = toRef(()=>siteConfigStore.landingAndroidImageGamblingSecond);
    const landingAndroidImageGamblingThird = toRef(()=>siteConfigStore.landingAndroidImageGamblingThird);
    const landingAndroidImageFree = toRef(()=>siteConfigStore.landingAndroidImageFree);
    const landingAndroidImageBonusFirst = toRef(()=>siteConfigStore.landingAndroidImageBonusFirst);
    const landingAndroidImageBonusSecond = toRef(()=>siteConfigStore.landingAndroidImageBonusSecond);
    const landingAndroidImageBonusThird = toRef(()=>siteConfigStore.landingAndroidImageBonusThird);
    const landingAndroidImageBackground = toRef(()=>siteConfigStore.landingAndroidImageBackground);
    const landingAndroidImageFooter = toRef(()=>siteConfigStore.landingAndroidImageFooter);
    const srcFirstBgImage = computed(()=>landingAndroidImageBackground.value?.src || '');
    const noLoadImage = require('web/src/modules/landings/submodules/android/pages/images/no-load.png');
    const firstImage = computed(()=>landingAndroidImageHeader.value?.src || '');
    const secondListImages = computed(()=>[
            landingAndroidImageGamblingFirst.value?.src || noLoadImage,
            landingAndroidImageGamblingSecond.value?.src || noLoadImage,
            landingAndroidImageGamblingThird.value?.src || noLoadImage
        ]);
    const thirdImage = computed(()=>landingAndroidImageFree.value?.src || '');
    const fourthListImages = computed(()=>[
            landingAndroidImageBonusFirst.value?.src || noLoadImage,
            landingAndroidImageBonusSecond.value?.src || noLoadImage,
            landingAndroidImageBonusThird.value?.src || noLoadImage
        ]);
    const footerImage = computed(()=>landingAndroidImageFooter.value?.src || noLoadImage);
    return {
        srcFirstBgImage,
        firstImage,
        secondListImages,
        thirdImage,
        fourthListImages,
        footerImage
    };
};
