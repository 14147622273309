// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-hero-timer_hxeoy{display:flex;flex-direction:column;row-gap:4px;align-items:center;width:100%;max-width:296px;padding:8px 0;background-color:var(--DOpacityLayer0);border-radius:12px;box-shadow:inset 0 0 0 1px var(--DLayer2)}.advent-hero-timer__number_dQWx4,.advent-hero-timer__numbers_ZZT3e{display:inline-flex;align-items:center}.advent-hero-timer__number_dQWx4{flex-direction:column;min-width:60px;min-height:60px}.advent-hero-timer__title_HaYRW{font-size:18px;font-weight:700;line-height:26px;color:var(--DTextDefault)}.advent-hero-timer__count_qXMoX{font-size:28px;font-weight:700;line-height:36px;padding:0 11px;color:var(--DTextDefault);min-height:40px}html.app__layout--desktop .advent-hero-timer__count_qXMoX{font-size:32px;line-height:40px}.advent-hero-timer__description_KySkk{font-size:14px;font-weight:400;line-height:24px;color:var(--DTextDefault)}.advent-hero-timer__colon_LDnH8{font-size:32px;font-weight:700;line-height:40px;color:var(--DTextDefault);align-self:baseline}.advent-hero-timer__info_KaH87{display:flex;flex-direction:column;gap:12px;align-items:center;padding:0 16px}.advent-hero-timer__info-image_H0QKu{flex-shrink:0;width:32px;height:32px;background-color:var(--DBrandDefault);border-radius:50%}.advent-hero-timer__info-image_H0QKu img{width:100%;height:100%;object-fit:contain}.advent-hero-timer__info-text_Ij1RB{font-size:16px;font-weight:500;line-height:24px;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .advent-hero-timer__info_KaH87{flex-direction:row;align-items:flex-start;padding-top:11px;padding-bottom:11px}html.app__layout--desktop .advent-hero-timer__info-text_Ij1RB{text-align:left}html.app__layout--desktop .advent-hero-timer_hxeoy{width:296px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-hero-timer": `advent-hero-timer_hxeoy`,
	"advent-hero-timer__number": `advent-hero-timer__number_dQWx4`,
	"advent-hero-timer__numbers": `advent-hero-timer__numbers_ZZT3e`,
	"advent-hero-timer__title": `advent-hero-timer__title_HaYRW`,
	"advent-hero-timer__count": `advent-hero-timer__count_qXMoX`,
	"advent-hero-timer__description": `advent-hero-timer__description_KySkk`,
	"advent-hero-timer__colon": `advent-hero-timer__colon_LDnH8`,
	"advent-hero-timer__info": `advent-hero-timer__info_KaH87`,
	"advent-hero-timer__info-image": `advent-hero-timer__info-image_H0QKu`,
	"advent-hero-timer__info-text": `advent-hero-timer__info-text_Ij1RB`
};
export default ___CSS_LOADER_EXPORT___;
