import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { toRef, watch, onMounted, onUnmounted, ref } from 'vue';
import { Timer } from '@leon-hub/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventHeroTimer',
    props: {
        timestamp: {},
        isBeforeStart: {
            type: Boolean
        },
        isFestivalEnded: {
            type: Boolean
        }
    },
    emits: [
        "end"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const time = ref({
            days: '',
            hours: '',
            minutes: '',
            seconds: ''
        });
        const timestampRef = toRef(()=>props.timestamp);
        let intervalId;
        function setTimer() {
            intervalId = Timer.setInterval(()=>{
                const now = Date.now();
                const diff = props.timestamp - now;
                if (diff <= 0) {
                    clearInterval(intervalId);
                    time.value = {
                        days: '00',
                        hours: '00',
                        minutes: '00',
                        seconds: '00'
                    };
                    emit('end');
                    return;
                }
                const days = Math.floor(diff / 86400000);
                const hours = Math.floor(diff / 3600000 % 24);
                const minutes = Math.floor(diff / 60000 % 60);
                const seconds = Math.floor(diff / 1000 % 60);
                time.value = {
                    days: days.toString().padStart(2, '0'),
                    hours: hours.toString().padStart(2, '0'),
                    minutes: minutes.toString().padStart(2, '0'),
                    seconds: seconds.toString().padStart(2, '0')
                };
            }, 1000);
        }
        onMounted(()=>{
            setTimer();
        });
        onUnmounted(()=>{
            clearInterval(intervalId);
        });
        watch(timestampRef, ()=>{
            setTimer();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-hero-timer'])
            }, [
                _ctx.isFestivalEnded ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['advent-hero-timer__info'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-hero-timer__info-image'])
                    }, [
                        _createElementVNode("img", {
                            src: require('web/src/modules/landings/submodules/advent/images/important.png'),
                            alt: ""
                        }, null, 8, _hoisted_1)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-hero-timer__info-text'])
                    }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TIMER_ENDED_DESCRIPTION')), 3)
                ], 2)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['advent-hero-timer__title'])
                    }, _toDisplayString(props.isBeforeStart ? _ctx.$t('WEB2_LANDING_ADVENT_TIMER_TITLE_BEFORE_START') : _ctx.$t('WEB2_LANDING_ADVENT_TIMER_TITLE_BEFORE_END')), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['advent-hero-timer__numbers'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__number'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__count'])
                            }, _toDisplayString(time.value.days), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__description'])
                            }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TIMER_DAYS')), 3)
                        ], 2),
                        (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__colon'])
                        }, " : ", 2)),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__number'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__count'])
                            }, _toDisplayString(time.value.hours), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__description'])
                            }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TIMER_HOURS')), 3)
                        ], 2),
                        (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__colon'])
                        }, " : ", 2)),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__number'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__count'])
                            }, _toDisplayString(time.value.minutes), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__description'])
                            }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TIMER_MINUTES')), 3)
                        ], 2),
                        (_openBlock(), _createElementBlock("span", {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__colon'])
                        }, " : ", 2)),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['advent-hero-timer__number'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__count'])
                            }, _toDisplayString(time.value.seconds), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['advent-hero-timer__description'])
                            }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TIMER_SECONDS')), 3)
                        ], 2)
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventHeroTimer'
                ]
            ]);
        };
    }
});
