import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-page-vip__body"
};
import { computed } from 'vue';
import LandingSectionFirst from 'web/src/modules/landings/system/sections/LandingSectionFirst.vue';
import LandingSectionSecond from 'web/src/modules/landings/system/sections/SecondSection/LandingSectionSecond.vue';
import LandingSectionThird from 'web/src/modules/landings/system/sections/LandingSectionThird.vue';
import LandingSectionFaq from 'web/src/modules/landings/system/sections/FaqSection/LandingSectionFaq.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import useGlobalLandingClass from 'web/src/modules/landings/useGlobalLandingClass';
import { LandingStyleAnimation, LandingStylePreset } from 'web/src/modules/landings/system/types';
import { useTheme } from 'web/src/modules/theme/composables';
import { useLandingVipRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingVipRoutePage',
    setup (__props) {
        const { $translate } = useI18n();
        const { faqListFormatted, onMainButtonClick, onContactUsButtonClick } = useLandingVipRoutePage();
        // tab icons
        const tabIcon_1 = require('web/src/modules/landings/submodules/vip/images/tab-icon-1.svg');
        const tabIcon_2 = require('web/src/modules/landings/submodules/vip/images/tab-icon-2.svg');
        const tabIcon_3 = require('web/src/modules/landings/submodules/vip/images/tab-icon-3.svg');
        const { isDark } = useTheme();
        // tab content icons
        const advantagesTabCashIcon_1 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/withdrawals.png') : require('web/src/modules/landings/submodules/vip/images/light/withdrawals.png'));
        const advantagesTabCashIcon_2 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/cashback.png') : require('web/src/modules/landings/submodules/vip/images/light/cashback.png'));
        const advantagesTabCashIcon_3 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/bonuses.png') : require('web/src/modules/landings/submodules/vip/images/light/bonuses.png'));
        const advantagesTabExclusiveIcon_1 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/access.png') : require('web/src/modules/landings/submodules/vip/images/light/access.png'));
        const advantagesTabExclusiveIcon_2 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/priority.png') : require('web/src/modules/landings/submodules/vip/images/light/priority.png'));
        const advantagesTabExclusiveIcon_3 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/support.png') : require('web/src/modules/landings/submodules/vip/images/light/support.png'));
        const advantagesTabLuxuryIcon_1 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/tournaments.png') : require('web/src/modules/landings/submodules/vip/images/light/tournaments.png'));
        const advantagesTabLuxuryIcon_2 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/gifts.png') : require('web/src/modules/landings/submodules/vip/images/light/gifts.png'));
        const advantagesTabLuxuryIcon_3 = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/giveaways.png') : require('web/src/modules/landings/submodules/vip/images/light/giveaways.png'));
        const gatesSrc = computed(()=>isDark.value ? require('web/src/modules/landings/submodules/vip/images/dark/gates.webp') : require('web/src/modules/landings/submodules/vip/images/light/gates.webp'));
        const secondListTab = [
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_1').value,
                icon: tabIcon_1,
                isActive: true
            },
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_2').value,
                icon: tabIcon_2,
                isActive: false
            },
            {
                label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_TITLE_3').value,
                icon: tabIcon_3,
                isActive: false
            }
        ];
        const secondListContent = [
            {
                list: [
                    {
                        image: advantagesTabCashIcon_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        image: advantagesTabCashIcon_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        image: advantagesTabCashIcon_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_CASH_CONTENT_DESCRIPTION_3').value
                    }
                ]
            },
            {
                list: [
                    {
                        image: advantagesTabExclusiveIcon_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        image: advantagesTabExclusiveIcon_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        image: advantagesTabExclusiveIcon_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_EXCLUSIVE_CONTENT_DESCRIPTION_3').value
                    }
                ]
            },
            {
                list: [
                    {
                        image: advantagesTabLuxuryIcon_1,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_1').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_1').value
                    },
                    {
                        image: advantagesTabLuxuryIcon_2,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_2').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_2').value
                    },
                    {
                        image: advantagesTabLuxuryIcon_3,
                        title: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_TITLE_3').value,
                        label: $translate('WEB2_LANDING_VIP_OUR_ADVANTAGES_TAB_LUXURY_CONTENT_DESCRIPTION_3').value
                    }
                ]
            }
        ];
        const { presetClass } = useGlobalLandingClass(LandingStylePreset.PRESET_2, LandingStyleAnimation.NO_ANIMATION);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "landing-page-vip",
                    _unref(presetClass)
                ])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(LandingSectionFirst, {
                        "is-premium": "",
                        title: _ctx.$t('WEB2_LANDING_VIP_MAIN_TITLE'),
                        "button-label": _ctx.$t('WEB2_LANDING_VIP_MAIN_BUTTON_LABEL'),
                        chevron: _ctx.$t('WEB2_LANDING_VIP_MAIN_CHEVRON_LABEL'),
                        label: _ctx.$t('WEB2_LANDING_VIP_MAIN_DESCRIPTION'),
                        onClick: _unref(onMainButtonClick)
                    }, null, 8, [
                        "title",
                        "button-label",
                        "chevron",
                        "label",
                        "onClick"
                    ]),
                    _createVNode(LandingSectionSecond, {
                        "is-premium": "",
                        title: _ctx.$t('WEB2_LANDING_VIP_OUR_ADVANTAGES_TITLE'),
                        label: _ctx.$t('WEB2_LANDING_VIP_OUR_ADVANTAGES_DESCRIPTION'),
                        list: secondListContent,
                        "list-tabs": secondListTab
                    }, null, 8, [
                        "title",
                        "label"
                    ]),
                    _createVNode(LandingSectionThird, {
                        "is-premium": "",
                        title: _ctx.$t('WEB2_LANDING_VIP_BECOMING_VIP_TITLE'),
                        label: _ctx.$t('WEB2_LANDING_VIP_BECOMING_VIP_DESCRIPTION'),
                        "button-label": _ctx.$t('WEB2_LANDING_VIP_BECOMING_VIP_BUTTON_LABEL'),
                        image: gatesSrc.value,
                        "data-attribute-names": {
                            contact: 'data-intercom-target'
                        },
                        "data-attribute-values": {
                            contact: 'contact-us'
                        },
                        onClick: _unref(onContactUsButtonClick)
                    }, null, 8, [
                        "title",
                        "label",
                        "button-label",
                        "image",
                        "onClick"
                    ]),
                    _createVNode(LandingSectionFaq, {
                        title: _ctx.$t('WEB2_LANDING_VIP_FAQ_TITLE'),
                        "faq-list": _unref(faqListFormatted)
                    }, null, 8, [
                        "title",
                        "faq-list"
                    ])
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingVipRoutePage'
                ]
            ]);
        };
    }
});
