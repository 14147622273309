// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-hero_tyzYR{height:360px}.advent-hero__wrapper_6s0nr{display:flex;flex-direction:column;gap:28px;align-items:center;justify-content:space-between;height:100%}.advent-hero__heading_tWYqa{display:flex;flex-direction:column;gap:12px;max-width:440px;text-align:center}html.app__layout--desktop .advent-hero__heading_tWYqa{max-width:600px}.advent-hero__timer_eqOFl{animation:slide-in-fwd-center_wtSVp .4s cubic-bezier(.895,.03,.685,.22) both;animation-delay:.75s}html.app__layout--desktop .advent-hero__wrapper_6s0nr{flex-direction:row;row-gap:48px}html.app__layout--desktop .advent-hero__heading_tWYqa{gap:6px;text-align:left}@keyframes slide-in-fwd-center_wtSVp{0%{opacity:0;transform:translateZ(-1400px)}to{opacity:1;transform:translateZ(0)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-hero": `advent-hero_tyzYR`,
	"advent-hero__wrapper": `advent-hero__wrapper_6s0nr`,
	"advent-hero__heading": `advent-hero__heading_tWYqa`,
	"advent-hero__timer": `advent-hero__timer_eqOFl`,
	"slide-in-fwd-center": `slide-in-fwd-center_wtSVp`
};
export default ___CSS_LOADER_EXPORT___;
