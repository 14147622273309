import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-section-third__body"
};
const _hoisted_2 = {
    class: "landing-section-third__header"
};
const _hoisted_3 = {
    class: "landing-section-third__paragraph"
};
const _hoisted_4 = {
    key: 0,
    class: "landing-section-third__body-image"
};
const _hoisted_5 = {
    class: "landing-section-third__footer"
};
const _hoisted_6 = {
    key: 0,
    class: "landing-section-third__image"
};
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import { LandingStylePreset } from 'web/src/modules/landings/system/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSectionThird',
    props: {
        chevron: {},
        image: {},
        title: {},
        label: {},
        buttonLabel: {},
        buttonLink: {},
        isPremium: {
            type: Boolean
        },
        dataAttributeNames: {},
        dataAttributeValues: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const showImageInBody = false;
        const showImageInImageColumn = props.image;
        function onButtonClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                class: _normalizeClass({
                    'landing-section-third': true,
                    'landing-section-third--premium': _ctx.isPremium
                })
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _ctx.chevron ? (_openBlock(), _createBlock(LandingChevron, {
                                    key: 0,
                                    label: _ctx.chevron,
                                    "is-premium": _ctx.isPremium
                                }, null, 8, [
                                    "label",
                                    "is-premium"
                                ])) : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(VDynamicContent, {
                                        class: "landing-section-third__title",
                                        content: _ctx.title
                                    }, null, 8, [
                                        "content"
                                    ]),
                                    _createVNode(VDynamicContent, {
                                        class: "landing-section-third__label",
                                        content: _ctx.label
                                    }, null, 8, [
                                        "content"
                                    ]),
                                    _unref(showImageInBody) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createVNode(VImage, {
                                            src: _ctx.image ? _ctx.image : '',
                                            width: "100%"
                                        }, null, 8, [
                                            "src"
                                        ])
                                    ])) : _createCommentVNode("", true)
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                                _createVNode(LandingButton, {
                                    label: _ctx.buttonLabel,
                                    preset: _ctx.isPremium ? _unref(LandingStylePreset).PRESET_2 : _unref(LandingStylePreset).PRESET_1,
                                    "is-premium": _ctx.isPremium,
                                    "button-link": _ctx.buttonLink,
                                    "data-attribute-name": _ctx.dataAttributeNames?.['contact'],
                                    "data-attribute-value": _ctx.dataAttributeValues?.['contact'],
                                    class: "landing-section-third__footer-button",
                                    onClick: onButtonClick
                                }, null, 8, [
                                    "label",
                                    "preset",
                                    "is-premium",
                                    "button-link",
                                    "data-attribute-name",
                                    "data-attribute-value"
                                ])
                            ])
                        ]),
                        _unref(showImageInImageColumn) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(VImage, {
                                src: _ctx.image ? _ctx.image : '',
                                width: "100%"
                            }, null, 8, [
                                "src"
                            ])
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LandingSectionThird'
                ]
            ]);
        };
    }
});
