import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { LandingStylePreset } from 'web/src/modules/landings/system/types';
import { useTheme } from 'web/src/modules/theme/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSectionFirst',
    props: {
        chevron: {},
        title: {},
        label: {},
        imageSrc: {},
        imageBg: {},
        isPremium: {
            type: Boolean
        },
        buttonLabel: {},
        buttonLink: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { isLight } = useTheme();
        function onButtonClick() {
            emit('click');
        }
        const styles = computed(()=>{
            "1";
            {
                const bgDesktop = isLight.value ? require('web/src/modules/landings/submodules/vip/images/light/main_desktop.webp') : require('web/src/modules/landings/submodules/vip/images/dark/main_desktop.webp');
                const bgTablet = isLight.value ? require('web/src/modules/landings/submodules/vip/images/light/main_middle_desktop.webp') : require('web/src/modules/landings/submodules/vip/images/dark/main_middle_desktop.webp');
                return {
                    '--bg-tablet': `url(${bgTablet})`,
                    '--bg-desktop': `url(${bgDesktop})`
                };
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-section-first']]: true,
                    [_ctx.$style['landing-section-first--premium']]: _ctx.isPremium
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-section-first'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-section-first__body'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['landing-section-first__header'])
                        }, [
                            _createVNode(LandingChevron, {
                                label: _ctx.chevron,
                                class: _normalizeClass(_ctx.$style['landing-section-first__chevron'])
                            }, null, 8, [
                                "label",
                                "class"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['landing-section-first__paragraph'])
                            }, [
                                _createVNode(VDynamicContent, {
                                    class: _normalizeClass(_ctx.$style['landing-section-first__title']),
                                    content: _ctx.title
                                }, null, 8, [
                                    "class",
                                    "content"
                                ]),
                                _createVNode(VDynamicContent, {
                                    class: _normalizeClass(_ctx.$style['landing-section-first__label']),
                                    content: _ctx.label
                                }, null, 8, [
                                    "class",
                                    "content"
                                ])
                            ], 2)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['landing-section-first__footer'])
                        }, [
                            _createVNode(LandingButton, {
                                label: _ctx.buttonLabel,
                                "is-premium": _ctx.isPremium,
                                preset: _ctx.isPremium ? _unref(LandingStylePreset).PRESET_2 : _unref(LandingStylePreset).PRESET_1,
                                class: _normalizeClass(_ctx.$style['landing-section-first__footer-button']),
                                onClick: onButtonClick
                            }, null, 8, [
                                "label",
                                "is-premium",
                                "preset",
                                "class"
                            ])
                        ], 2),
                        _createCommentVNode("", true)
                    ], 2)
                ], 2),
                (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['landing-section-first__bg']),
                    style: _normalizeStyle(styles.value)
                }, null, 6))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingSectionFirst'
                ]
            ]);
        };
    }
});
