import { computed, onMounted, watch, ref } from 'vue';
import { useWindowResize } from '@leon-hub/browser-composables';
import { packListStateActive } from '@leon-hub/api-sdk';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
export default function useAdventCalendar(props) {
    const swiper = ref();
    const hasStubs = ref(false);
    const stubsItems = ref([]);
    const groupedPassItems = computed(()=>{
        const groups = [];
        if (props.items) for(let i = 0; i < props.items.length; i += 6)groups.push(props.items.slice(i, i + 6));
        return groups;
    });
    const activeIndex = computed(()=>groupedPassItems.value.findIndex((group)=>group.some((card)=>card.state === packListStateActive)));
    function setStubsVisibility() {
        hasStubs.value = document.documentElement.clientWidth > 1085;
    }
    function updateItems() {
        if (props.items?.length) {
            const startDate = props.items[0].startedAt;
            let startDayOfWeek = new Date(startDate).getDay();
            startDayOfWeek = 0 === startDayOfWeek ? 6 : startDayOfWeek - 1;
            const oneDayMilliseconds = 86400000;
            for(let i = 0; i < startDayOfWeek; i += 1){
                const stubTimestamp = new Date(startDate - i * oneDayMilliseconds).toISOString().split('T')[0];
                stubsItems.value.unshift(stubTimestamp);
            }
            setStubsVisibility();
        }
    }
    function slideToActiveSlide() {
        swiper.value?.slideToSlide(activeIndex.value);
    }
    useWindowResize(()=>{
        setStubsVisibility();
    });
    onMounted(()=>{
        updateItems();
    });
    watch(()=>props.items?.length, updateItems);
    useIntersectionObserver(swiper, ()=>{
        slideToActiveSlide();
    }, void 0, true);
    return {
        groupedPassItems,
        hasStubs,
        stubsItems,
        swiper
    };
}
