// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-calendar-stub_raqU8{position:relative;display:flex;flex-direction:column;gap:40px;align-items:center;justify-content:center;padding:68px 16px;background-color:var(--DOpacityLayer1);border-radius:32px}.advent-calendar-stub__img_iTQJO{order:2;width:100%;max-width:548px;pointer-events:none;-webkit-user-select:none;user-select:none}.advent-calendar-stub__text_00P5J{font-size:16px;font-weight:400;line-height:24px;order:1;color:var(--DTextPrimary);text-align:center}html.app__layout--desktop .advent-calendar-stub__text_00P5J{font-size:24px;line-height:32px}.advent-calendar-stub__hint_aA3UO{font-size:14px;font-weight:400;line-height:24px;order:3;max-width:375px;margin-top:-24px;color:var(--DTextSecondary);text-align:center}html.app__layout--desktop .advent-calendar-stub__hint_aA3UO{font-size:16px;font-weight:400;line-height:24px}.advent-calendar-stub__button_hQU6Y{order:3;width:100%;max-width:375px}html.app__layout--desktop .advent-calendar-stub_raqU8{padding-right:32px;padding-left:32px}html.app__layout--desktop .advent-calendar-stub__img_iTQJO{order:1}html.app__layout--desktop .advent-calendar-stub__text_00P5J{order:2}html.app__layout--desktop .advent-calendar-stub__button_hQU6Y{order:3}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-calendar-stub": `advent-calendar-stub_raqU8`,
	"advent-calendar-stub__img": `advent-calendar-stub__img_iTQJO`,
	"advent-calendar-stub__text": `advent-calendar-stub__text_00P5J`,
	"advent-calendar-stub__hint": `advent-calendar-stub__hint_aA3UO`,
	"advent-calendar-stub__button": `advent-calendar-stub__button_hQU6Y`
};
export default ___CSS_LOADER_EXPORT___;
