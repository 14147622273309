// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advent-prizes-modal_ksEey{min-height:100vh;padding:16px;background-color:var(--DLayer0)}.advent-prizes-modal__content_SbvB3{margin:0}html.app__layout--desktop .advent-prizes-modal__content_SbvB3{margin:0}.advent-prizes-modal__list_t3wIq{display:flex;flex-direction:column;row-gap:16px}html.app__layout--desktop .advent-prizes-modal_ksEey{min-height:auto;padding:16px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advent-prizes-modal": `advent-prizes-modal_ksEey`,
	"advent-prizes-modal__content": `advent-prizes-modal__content_SbvB3`,
	"advent-prizes-modal__list": `advent-prizes-modal__list_t3wIq`
};
export default ___CSS_LOADER_EXPORT___;
